import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Social = ({ Social }) => {
  return (
    <div className="flex justify-start gap-10 md:items-center">
      <div>
        <a href="https://twitter.com/monexusinc" target="_blank">
          <StaticImage
            src="../images/Twitter.svg"
            width={40}
            quality={100}
            placeholder="tracedSVG"
            formats={["auto", "webp", "avif"]}
            alt="Monexusinc Twitter"
          />
        </a>
      </div>
      <div>
        <a href="https://www.instagram.com/monexusinc/" target="_blank">
          <StaticImage
            src="../images/InstaG.svg"
            width={40}
            quality={95}
            formats={["auto", "webp", "avif"]}
            placeholder="tracedSVG"
            alt="Monexusinc Instagram"
          />
        </a>
      </div>
      {/*    <div>
        <a href="/" target="_blank">
          <StaticImage
            src="../images/Facebook.svg"
            width={40}
            quality={95}
            formats={["auto", "webp", "avif"]}
            placeholder="tracedSVG"
            alt="Monexusinc Facebook"
          />
        </a>
      </div> */}
    </div>
  )
}

Social.defaultProps = {
  Social: "Under construction",
}

export default Social
